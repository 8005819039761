const Footer = () => {
    return(
        <div className="web-footer">
            <div>
                <img alt="" src="/images/logo.svg" width="30" height="30" className="d-inline-block align-top" />
                <span className="web-brand-title">Pandora CodeBox </span>
                <span className="web-owner-title">by Luis Fernando Aguirre</span>
            </div>
            <div>
            © Todos los derechos reservados - 2023 - <a href="mailto:contacto@pandoracodebox.com">contacto@pandoracodebox.com</a>
            </div>
        </div>
    );
}

export default Footer